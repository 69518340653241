<template>
  <v-dialog v-model="active" width="700">
    <v-card>
      <v-card-title>{{ id === '' ? 'New' : 'Edit'}} Entry</v-card-title>
      <v-card-text style="min-height:300px">
        <v-row>
          <v-col :cols="6">
            <v-autocomplete v-model="category" :items="categories" label="Entry Category" outlined hide-details></v-autocomplete>
          </v-col>
          <v-col :cols="6">
            <v-combobox v-model="subcategory" :items="subcategoryOptions" :disabled="category == null || category === ''" label="Subcategory" outlined hide-details></v-combobox>
          </v-col>
          <v-col :cols="4">
            <date-picker v-model="date" :disabled="category == null || category === ''" label="Date" outlined hide-details></date-picker>
          </v-col>
          <v-col :cols="4">
            <v-text-field v-model="author" :disabled="category == null || category === ''" label="Author" outlined hide-details></v-text-field>
          </v-col>
          <v-col :cols="4">
            <v-select v-model="permission" :items="permissionOptions" label="Access Permissions" :disabled="category == null || category === ''" outlined hide-details></v-select>
          </v-col>
          <v-col :cols="12">
            <v-text-field v-model="title" :disabled="category == null || category === ''" label="Title" outlined hide-details></v-text-field>
          </v-col>
          <v-col :cols="12">
            <v-text-field v-model="description" :disabled="category == null || category === ''" label="Description" outlined hide-details></v-text-field>
          </v-col>
          <v-col :cols="3">
            <v-dialog v-model="thumbnailDialog" :disabled="category == null || category === ''" width="450">
              <template v-slot:activator="{ on }">
                <div v-if="thumbnail" v-on="on">
                  <v-img :src="thumbnail" contain style="width:64px;height:64px"></v-img>
                  <span>Click to change thumbnail</span>
                </div>
                <div v-else v-on="on" style="width:60px;height:80px;border:1px solid #CCC;font-size:.6em;padding:1em .5em">No Image; Click to Upload</div>
              </template>
              <v-card>
                <v-card-title>Upload Thumbnail</v-card-title>
                <v-card-text>
                  <p>Upload an image to use as the thumbnail. If no image is uploaded then the Covenant shield will be used by default.</p>
                  <v-file-input v-model="thumbnailFile" label="Thumbnail" accept="image/*" outlined></v-file-input>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="thumbnailDialog = false">Cancel</v-btn>
                  <v-btn text color="success" @click="addThumbnail">Save Thumbnail</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col :cols="9">
            <v-file-input v-model="file" :disabled="category == null || category === ''" label="File (only accepts mp3 and pdf files)" accept=".mp3,.pdf" outlined></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="active = false">Cancel</v-btn>
        <v-btn :disabled="isSaving" color="success" outlined @click="save">{{ isSaving ? 'Saving...' : 'Save' }}</v-btn>
        <v-spacer></v-spacer>
        <v-dialog v-model="removeDialog" width="400" persistent>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="error" outlined>Delete</v-btn>
          </template>
          <v-card>
            <v-card-title>Remove Entry</v-card-title>
            <v-card-text>
              <p>This will permenantly delete this entry. It cannot be undone.</p>
              <v-checkbox v-if="fileLink != null && fileLink !== ''" v-model="deleteDriveFile" label="Also delete the Google Drive file" />
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="removeDialog = false">Cancel</v-btn>
              <v-btn color="success" text @click="remove">Delete Entry</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-btn v-if="id != null && fileLink != null && fileLink !== ''" :to="'/library/archives/' + id" outlined>View Entry</v-btn>
        <v-btn v-if="fileLink != null && fileLink !== ''" :href="'https://drive.google.com/file/d/' + fileLink" target="_blank" outlined>
          View Drive File
          <v-icon right>fab fa-google-drive</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { uploadFile } from '@/helpers/functions'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  components: {
    DatePicker: () => import('@/components/forms/inputs/DatePicker')
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  setup (props, { root, emit }) {
    const active = ref(false)

    function activate () {
      active.value = true
    }

    const category = ref('')
    const categories = computed(() => root.$store.state.library.archives.categories.map(({ name }) => name).sort())
    const subcategory = ref('')
    const subcategoryOptions = ref([])
    const title = ref('')
    const date = ref('')
    const description = ref('')
    const author = ref('')
    const thumbnail = ref(null)
    const file = ref(null)
    const fileLink = ref('')
    const permission = ref('')
    const permissionOptions = ref(['Public', 'Campus', 'Restricted'])

    const service = root.$feathers.service('library/archive/entry')
    const catServ = root.$feathers.service('library/archive/category')

    watch(category, () => {
      if (category.value != null && category.value !== '') {
        const aggregate = [
          { $match: { category: category.value } },
          { $group: { _id: '$subcategory', count: { $sum: 1 } } },
          { $sort: { _id: 1 } }
        ]
        service.find({ query: { aggregate } }).then(({ data }) => {
          subcategoryOptions.value = data.map(({ _id }) => _id)
        })
      }
    })

    watch(() => props.id, () => {
      if (props.id != null && props.id !== '') {
        service.get(props.id).then((obj) => {
          category.value = obj.category || ''
          subcategory.value = obj.subcategory || ''
          title.value = obj.title || ''
          date.value = obj.date.substring(0, 10) || ''
          description.value = obj.description || ''
          author.value = obj.author || ''
          thumbnail.value = obj.thumbnail || ''
          fileLink.value = obj.fileLink || ''
          permission.value = obj.permission || ''
        })
      } else {
        category.value = ''
        subcategory.value = ''
        title.value = ''
        date.value = ''
        description.value = ''
        author.value = ''
        thumbnail.value = ''
        fileLink.value = ''
        permission.value = 'Public'
      }
    })

    onMounted(() => {
      if (categories.value.length === 0) {
        root.$store.dispatch('library/loadArchiveCategories')
      }
    })

    const thumbnailDialog = ref(false)
    const thumbnailFile = ref(null)
    function addThumbnail () {
      const img = document.createElement('img')
      const reader = new FileReader()
      reader.onload = () => { img.src = reader.result }
      reader.onerror = (err) => { console.error(err) }
      reader.readAsDataURL(thumbnailFile.value)
      img.onload = () => {
        let width = img.width
        let height = img.height
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)
        const maxWidth = 60
        const maxHeight = 80
        if (width > maxWidth) {
          height *= maxWidth / width
          width = maxWidth
        }
        if (height > maxHeight) {
          width *= maxHeight / height
          height = maxHeight
        }
        canvas.width = width
        canvas.height = height
        const ctx1 = canvas.getContext('2d')
        ctx1.drawImage(img, 0, 0, width, height)
        thumbnail.value = canvas.toDataURL('image/png')
      }
      thumbnailDialog.value = false
    }

    const isSaving = ref(false)
    async function save () {
      isSaving.value = true
      // Look up the category; this will be used to update
      const { data: catData } = await catServ.find({ query: { name: category.value } })
      let dt = new Date(date.value)
      dt.setHours(dt.getHours() + 10)
      const obj = {
        category: category.value,
        subcategory: subcategory.value,
        title: title.value,
        date: dt,
        description: description.value,
        author: author.value,
        permission: permission.value
      }
      if (thumbnailFile.value != null) {
        obj.thumbnail = {
          data: thumbnail.value,
          type: 'image/png'
        }
        // Check and see if the date entered for this entry is after the last one in the category
        if (catData.length > 0) {
          let newDate = new Date(date.value)
          let oldDate = new Date(catData[0].last)
          if (newDate > oldDate) {
            // If so, update the thumbnail and last dates for the category
            await catServ.patch(catData[0]._id, { thumbnail: obj.thumbail, last: date.value })
          }
        }
      }
      if (file.value) {
        // Determine what the filename should be based on the section and title (or date for Chapels)
        const parts = [obj.category, obj.title]
        if (obj.category === 'Chapel') {
          // For chapels, we are going to include the chapel date and speaker along with the title
          parts.splice(1, 0, stringFormatDate(obj.date, false, false))
          parts.splice(2, 0, obj.subcategory)
        } else if (obj.title.search(obj.category) >= 0) {
          parts.splice(0, 1)
        }
        const filename = parts.join(' - ') + file.value.name.substring(file.value.name.lastIndexOf('.'))
        try {
          // Look up the folder into which this file should be placed; the folder is stored as part of the categories list in the store
          const rec = root.$store.state.library.archives.categories.filter(({ name }) => name === category.value)
          if (rec.length === 0) {
            root.$store.dispatch('main/snackbar', { color: 'error', text: 'Could not find the category in the store' })
            return
          }
          const [a, b] = file.value.type.split('/')
          if (a === 'application' || a === 'audio' || a === 'video') obj.format = b
          else {
            alert('File has an invalid mime type. Please contact Technology Services for assistance')
            isSaving.value = false
            return
          }
          const { folder } = rec[0]
          obj.fileLink = await uploadFile({ file: file.value, filename, folder, extraFormData: { category: obj.category } }, 'library/archive/drive-file', true)
        } catch (e) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error uploading file: ' + e })
          isSaving.value = false
          return
        }
      }
      try {
        if (props.id != null && props.id !== '') {
          await service.patch(props.id, obj)
        } else {
          await service.create(obj)
        }
        active.value = false
        emit('updated', obj)
      } catch (e) {
        console.error(e)
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error saving entry: ' + e })
      }
      // Update the category count
      isSaving.value = false
    }

    const removeDialog = ref(false)
    const deleteDriveFile = ref(false)
    async function remove () {
      try {
        await service.remove(props.id, { query: { deleteDriveFile: deleteDriveFile.value } })
        root.$store.dispatch('main/snackbar', { color: 'success', text: 'Entry was removed successfully.' })
        emit('removed')
        active.value = false
      } catch (e) {
        console.error(e)
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error removing entry: ' + e })
      }
    }

    return {
      active,
      activate,
      category,
      categories,
      subcategory,
      subcategoryOptions,
      title,
      date,
      description,
      author,
      file,
      fileLink,
      permission,
      permissionOptions,
      thumbnail,
      thumbnailDialog,
      thumbnailFile,
      addThumbnail,
      isSaving,
      save,
      removeDialog,
      deleteDriveFile,
      remove
    }
  }
}
</script>
